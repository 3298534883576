import type {RunningTime, ThinRunningTime} from '@hconnect/common/types'

import {EquipmentData} from './operationHours.types'

export type RunningTimesOffPeriodsWithoutDetail = {
  runningTime: ThinRunningTime
  equipment: EquipmentData
}

export type ThinEquipmentRunningTimes = {
  equipment: EquipmentData
  runningTimes: ThinRunningTime[]
}

export type EquipmentRunningTimes = {
  equipment?: EquipmentData
  runningTimes?: RunningTime[]
  totalRunning: string
  totalStopped?: string
  productionVolumePerHour: number
  cementType?: string
}

export type EquipmentRunningTimesResult = {
  success: boolean
  errorMessage?: string
  plantId: string
  equipmentsWithRunningTimes: EquipmentRunningTimes[]
}
